var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user-profile "
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-navbar', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  }, [_vm._t("navbar", [_c('profile-header')])], 2), _c('div', {
    staticClass: "mt-4 mb-4"
  }, [_c('center', [_c('span', {
    staticClass: "text-center",
    staticStyle: {
      "font-size": "2rem",
      "color": "black"
    }
  }, [_c('b', [_c('span', {
    staticClass: "text-primary"
  }, [_vm._v(_vm._s(_vm.data.title))])])]), _c('br'), _c('h5', {
    staticClass: "mt-2"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.data.subtitle)
    }
  })])])], 1), _c('div', {
    staticClass: "container",
    staticStyle: {
      "width": "90%"
    }
  }, [_c('section', {
    attrs: {
      "id": "card-images"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "xl": "4"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": "",
      "img-src": _vm.apiFile + _vm.data.thumbnail
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "xl": "8"
    }
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.data.content)
    }
  })])], 1)], 1)])], 1), _c('footer-home')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }